import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'semantic-ui-react';
import { DebounceInput } from 'react-debounce-input';
import { ImgCheckMark } from '../../../images/images';
import { fetchMoreItems } from '../../../DDPJS/DDPJS';
import { FormattedMessage } from 'react-intl';
import { useClickOutside } from '../../../hooks';

interface OptionProps {
  image?: any;
  seperator?: boolean;
  selected?: boolean;
  text?: string;
  value: number | string;
  exclusive?: boolean;
  fallbackImage?: string;
  subText?: string;
  assignToMe?: boolean;
}

interface IProps {
  multiSelection?: boolean;
  onSelectionChanged(newValue: any, changedMultiSelection?: boolean): void;
  subscriptionId?: string;
  options?: OptionProps[];
  findData?: { onFindTextChanged(text: string): void };
  fitToElement?: any;
  onClose(): void;
}

export const FloatingSelection: FC<IProps> = memo(
  ({
    multiSelection,
    options,
    onSelectionChanged,
    subscriptionId,
    findData,
    fitToElement,
    onClose,
  }) => {
    const selectedValues: any[] = [];
    options?.forEach((item) => {
      if (item.selected) selectedValues.push(item.value);
    });

    const [currentSelectedValues, setCurrentSelectedValues] =
      useState(selectedValues);
    const [changedMultiSelection, setChangedMultiSelection] = useState(false);
    const [findText, setFindText] = useState('');

    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (findData) {
        applyNewFindTextChange(findText);
      }
    }, []);

    const handleClickOutside = useCallback(() => {
      onClose();
    }, [onClose]);

    useClickOutside(wrapperRef, handleClickOutside);

    const isExclusive = (value: number | string): boolean => {
      if (options) {
        for (const option of options) {
          if (option.value === value && option.exclusive) return true;
        }
      }

      return false;
    };

    const isMatchFindText = (itemText?: string) => {
      if (findData && findText === '') return true;

      return (
        String(itemText)
          .toLowerCase()
          .indexOf(String(findText).toLowerCase()) !== -1
      );
    };

    const applyNewFindTextChange = (newFindText: string) => {
      setFindText(newFindText);
      findData?.onFindTextChanged(newFindText);
    };

    const handleSelectionChanged = (
      value: string | number,
      isAssignToMe: boolean | undefined,
    ) => {
      if (multiSelection) {
        let selectedValues = [...currentSelectedValues];
        if (isExclusive(value)) {
          selectedValues = [value];
        } else {
          let unselectExisting = false;
          for (const selectedValue of selectedValues) {
            if (isExclusive(selectedValue)) {
              unselectExisting = true;
              break;
            }
          }

          if (unselectExisting) {
            selectedValues = [value];
          } else {
            const existingIndex = selectedValues.indexOf(value);
            if (existingIndex === -1) selectedValues.push(value);
            else if (!isAssignToMe) selectedValues.splice(existingIndex, 1);
          }
        }
        setCurrentSelectedValues(() => selectedValues);

        setChangedMultiSelection(true);
      } else {
        onSelectionChanged(value);
      }
    };

    const maxWidth = 320;
    let minWidth = 0;
    if (fitToElement)
      minWidth = Math.min(
        fitToElement.current.getBoundingClientRect().width,
        maxWidth,
      );

    return (
      <div className="floatingselection js-floatingselection" ref={wrapperRef}>
        <div
          className="floatingselection-list .js-floatingselection-list"
          onScroll={(event) => {
            if (!subscriptionId) return; // Not able to fetch more results

            const element = event.currentTarget;
            if (
              element.scrollHeight - element.scrollTop ===
              element.clientHeight
            )
              fetchMoreItems(subscriptionId, 5);
          }}
        >
          {options?.map((option) => {
            const isSelected =
              currentSelectedValues.indexOf(option.value) !== -1;

            if (!isMatchFindText(option.text)) {
              return null;
            }

            const classes = [
              'floatingselection-item',
              'js-floatingselection-item',
            ];
            if (isSelected && !multiSelection) classes.push('is-selected');

            if (option.seperator) {
              return (
                <div key="seperator" className="floatingselection-seperator" />
              );
            }

            const itemNameClasses = ['floatingselection-item-name'];
            if (option.value === 'invalid') itemNameClasses.push('can-wrap');

            return (
              <div
                key={option.text}
                className={classes.join(' ')}
                onClick={() => {
                  handleSelectionChanged(option.value, option.assignToMe);
                }}
                style={{ maxWidth: maxWidth + 'px', minWidth: minWidth + 'px' }}
              >
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  {typeof option.image === 'string' ? (
                    <div className={itemNameClasses.join(' ')}>
                      {option.image && (
                        <div className="js-floatingselection-item-name">
                          <img
                            className="floatingselection-item-icon"
                            src={option.image}
                            alt={''}
                            onError={(event) => {
                              if (option.fallbackImage)
                                event.currentTarget.src = option.fallbackImage;
                            }}
                          />
                        </div>
                      )}
                      <div className="js-floatingselection-item-name">
                        {option.text}
                      </div>
                    </div>
                  ) : (
                    <div className={itemNameClasses.join(' ')}>
                      {option.image && (
                        <div className="floatingselection-item-icon-component">
                          {option.image}
                        </div>
                      )}
                      <div className="js-floatingselection-item-name">
                        {option.text}
                      </div>
                    </div>
                  )}
                  {multiSelection && (
                    <div className="floatingselection-item-check">
                      {isSelected && !option.assignToMe && (
                        <img src={ImgCheckMark} alt="" />
                      )}
                    </div>
                  )}
                </div>
                {option.subText && (
                  <div className="floatingselection-item-subtext">
                    {option.subText}
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className="floatingselection-bottomarea">
          {findData && (
            <DebounceInput
              className="floatingselection-findfield js-floatingselection-findfield"
              minLength={2}
              debounceTimeout={300}
              onChange={(event) => applyNewFindTextChange(event.target.value)}
              value={findText}
            />
          )}
          {multiSelection && (
            <div className="floatingselection-buttonbox js-floatingselection-buttonbox">
              <Button
                onClick={() =>
                  onSelectionChanged(
                    currentSelectedValues,
                    changedMultiSelection,
                  )
                }
              >
                <FormattedMessage id="GENERAL.ok" />
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  },
);

export default FloatingSelection;
