import { FC, memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { String } from '../../../Fields/String';
import { Number } from '../../../Fields/Number';
import { DateTime } from '../../../Fields/DateTime';
import { EnumMultiSelect } from '../../../Fields/EnumMultiSelect';
import { Resources } from '../../../Fields/Resources';
import { Allocations } from '../../../Fields/Allocations';
import { ReadOnly } from '../../../Fields/ReadOnly';
import { StatusSingleSelect } from '../../../Fields/StatusSingleSelect';
import { PrioritySingleSelect } from '../../../Fields/PrioritySingleSelect';
import { EnumSingleSelect } from '../../../Fields/EnumSingleSelect';
import { MilestoneMultiSelect } from '../../../Fields/MilestoneMultiSelect';
import { TimeZone } from '../../../Fields/TimeZone';
import { WorkflowStatusSingleSelect } from '../../../Fields/WorkflowStatusSingleSelect';
import { TimeSpent } from '../../../Fields/TimeSpent';
import { ColorSingleSelectField } from '../../../Fields/ColorSingleSelectField';
import { LinkedTo } from '../../../Fields/LinkedTo';
import { getFieldDefinition } from '../../../Fields/helpers';
import { getProjectInfo } from '../../../../util/project/getProjectInfo';
import { filterCoreFieldsByProject } from '../../../../util/project/filterFieldsByProject';
import {
  taskHasWorkflow,
  taskIsInSprint,
} from '../../../../util/task/propertyHelpers';
import { isValidObject } from '../../../../util/validationFunctions';
import { HyperlinkInput } from '../../../Fields/HyperlinkInput';
import {
  GlobalState,
  Milestone,
  SingleLineFieldBase,
  Task,
} from '../../../../interfaces';
import { Theme } from '../../../../enums';

// TODO: Refactor this component and split it. The code here is awful

interface IProps {
  task: Task;
  theme: Theme;
  milestones: Milestone[];
}

export const SingleLineTab: FC<IProps> = memo(({ task, theme, milestones }) => {
  const [projectID, setProjectID] = useState<number>(
    parseInt(task.$ProjectID, 10),
  );

  const singleLineFields = useSelector(
    (state: GlobalState) => state.singleLineFields,
  );
  const resources = useSelector((state: GlobalState) => state.resources);
  const resourceGroups = useSelector(
    (state: GlobalState) => state.resourceGroups,
  );

  useEffect(() => {
    if (projectID !== parseInt(task.$ProjectID, 10)) {
      setProjectID(parseInt(task.$ProjectID, 10));
    }
  }, [task.$ProjectID]);

  const renderField = (fieldName: string | undefined, fields: any[]) => {
    const field = fields.find((field) => field.id === fieldName);
    const projectData = getProjectInfo(projectID);
    const projectType = projectData[0]?.Type;

    // these are fields that may not have metadata depending on project type
    if (!isValidObject(field)) {
      if (fieldName === 'WorkRemaining') {
        if (projectData[1]) {
          return (
            <Number key={'WorkRemaining'} task={task} fieldID={field.id} />
          );
        }
      } else if (fieldName === 'SprintPriority') {
        if (projectType === 'QA') {
          return null;
        }
        if (projectData[1]) {
          const sprintPriorityField = singleLineFields.find((field) => {
            return (
              field.id === fieldName && field.projectID === projectData[1]?.id
            );
          });
          return (
            <PrioritySingleSelect
              key={sprintPriorityField?.id}
              task={task}
              fieldID={field.id}
            />
          );
        }
      }

      return null;
    }

    // do not display these fields in the web todo
    const noDisplayFields = [
      'Comment',
      'LinkedToSprint',
      'Description',
      'WorkflowStatus',
      'Type',
      'LockedType',
      'Archived',
    ];
    if (projectType === 'QA') {
      noDisplayFields.push(
        'Status',
        'Severity',
        'SprintPriority',
        'BugPriority',
        'ResourceAllocationFirst',
      );
    } else if (
      projectType === 'Backlog' &&
      task.fields.CommittedToProjectID === undefined
    ) {
      noDisplayFields.push(
        'Status',
        'BacklogPriority',
        'SprintPriority',
        'WorkRemaining',
        'Duration',
      );
    } else if (
      projectType === 'Backlog' ||
      (projectType === 'Planning' && taskIsInSprint(task))
    ) {
      noDisplayFields.push(
        'Status',
        'WorkRemaining',
        'SprintPriority',
        'ResourceAllocationFirst',
      );
    } else if (projectType === 'Planning' && !taskIsInSprint(task)) {
      noDisplayFields.push('Status');
    }

    if (noDisplayFields.includes(field.id)) {
      return null;
    }

    if (
      field.Type === 'Workflow' ||
      field.id === 'Duration' ||
      field.id === 'BoardCommit_ColumnID' ||
      field.id === 'BoardCommit_LaneID'
    ) {
      return <ReadOnly key={field.id} task={task} fieldID={field.id} />;
    }

    if (field.Type === 'String') {
      return <String key={field.id} task={task} fieldID={field.id} />;
    } else if (field.Type === 'Hyperlink') {
      return <HyperlinkInput key={field.id} task={task} fieldID={field.id} />;
    } else if (field.Type === 'Enum' || field.Type === 'InlineEnum') {
      if (field.id === 'Status') {
        if (taskHasWorkflow(task)) {
          return (
            <WorkflowStatusSingleSelect
              key={field.id}
              task={task}
              fieldID={field.id}
            />
          );
        } else {
          return <StatusSingleSelect key={field.id} task={task} />;
        }
      } else if (field.id === 'SprintPriority' || field.id === 'BugPriority') {
        return (
          <PrioritySingleSelect key={field.id} task={task} fieldID={field.id} />
        );
      } else if (field.id === 'Color') {
        return (
          <ColorSingleSelectField
            key={field.id}
            task={task}
            fieldId={field.id}
            theme={theme}
          />
        );
      } else {
        return (
          <EnumSingleSelect key={field.id} task={task} fieldID={field.id} />
        );
      }
    } else if (field.Type === 'Hours' && field.id.indexOf('CC_') === 0) {
      return <TimeSpent key={field.id} task={task} fieldID={field.id} />;
    } else if (
      field.Type === 'Integer' ||
      field.Type === 'Float' ||
      field.Type === 'Hours'
    ) {
      return (
        <Number
          key={field.id}
          task={task}
          fieldID={field.id}
          unit={field.Unit}
        />
      );
    } else if (field.Type === 'DateTime' || field.Type === 'DateTimeTime') {
      return <DateTime key={field.id} task={task} fieldID={field.id} />;
    } else if (field.Type === 'MultiEnum' || field.Type === 'InlineMultiEnum') {
      return <EnumMultiSelect key={field.id} task={task} fieldID={field.id} />;
    } else if (field.Type === 'Resources') {
      return (
        <Resources
          key={field.id}
          task={task}
          fieldID={field.id}
          groups={resourceGroups}
          resources={resources}
        />
      );
    } else if (field.Type === 'ResourceAllocations') {
      return <Allocations key={field.id} task={task} fieldID={field.id} />;
    } else if (field.Type === 'MilestoneMultiEnum') {
      return (
        <MilestoneMultiSelect
          key={field.id}
          task={task}
          fieldID={field.id}
          milestones={milestones}
        />
      );
    } else if (
      projectType === 'QA' &&
      field.Type === 'BugPriority' &&
      taskIsInSprint(task)
    ) {
      return (
        <PrioritySingleSelect
          key={field.id}
          task={task}
          fieldID={'BugPriority'}
        />
      );
    } else if (
      field.Type === 'LinkedTo' &&
      task.fields['LinkedTo'].length > 0
    ) {
      return (
        <LinkedTo
          linkedToItems={task.fields['LinkedTo']}
          displayName={getFieldDefinition('LinkedTo', task).DisplayName}
        />
      );
    } else {
      return null;
    }
  };

  const sortByFieldName = (first: any, second: any): number => {
    if (first.DisplayName.toUpperCase() < second.DisplayName.toUpperCase())
      return -1;
    if (first.DisplayName.toUpperCase() > second.DisplayName.toUpperCase())
      return 1;

    return 0;
  };

  const buildFieldNameArray = () => {
    const fieldDefArray: (SingleLineFieldBase | undefined)[] = [];

    const projectData = getProjectInfo(task.$ProjectID);
    const CommittedToProjectID = parseInt(task.fields.CommittedToProjectID);

    const fieldNameArray = Object.keys(task.fields);
    fieldNameArray.forEach((item) => {
      const fieldDef = singleLineFields.find((field) => {
        return (
          field.id === item &&
          (field.projectID === projectData[0]?.id ||
            field.projectID === CommittedToProjectID)
        );
      });
      if (isValidObject(fieldDef)) {
        fieldDefArray.push(fieldDef);
      }
    });

    fieldDefArray.push(
      singleLineFields.find(
        (singleLineField) => singleLineField.id === 'DatabaseID',
      ),
    );

    return fieldDefArray;
  };

  const projectData = getProjectInfo(task.$ProjectID);
  const projectType = projectData[0]?.Type;

  const fieldDefs = buildFieldNameArray();
  const assignedField = filterCoreFieldsByProject(
    parseInt(task.$ProjectID, 10),
  ).find((field) => {
    return field.id === 'ResourceAllocationFirst';
  });

  let prioritisedFields;
  if (projectType === 'QA') {
    prioritisedFields = (
      <div className="importantfields">
        {taskHasWorkflow(task) ? (
          <WorkflowStatusSingleSelect task={task} fieldID={'Status'} />
        ) : (
          <StatusSingleSelect task={task} />
        )}
        <EnumSingleSelect task={task} fieldID={'Severity'} />
        {taskIsInSprint(task) ? (
          <PrioritySingleSelect task={task} fieldID={'SprintPriority'} />
        ) : (
          <PrioritySingleSelect task={task} fieldID={'BugPriority'} />
        )}
        {isValidObject(assignedField) && (
          <Allocations task={task} fieldID={'ResourceAllocationFirst'} />
        )}
      </div>
    );
  } else if (taskIsInSprint(task)) {
    prioritisedFields = (
      <div className="importantfields">
        {taskHasWorkflow(task) ? (
          <WorkflowStatusSingleSelect task={task} fieldID={'Status'} />
        ) : (
          <StatusSingleSelect task={task} />
        )}
        <PrioritySingleSelect task={task} fieldID={'SprintPriority'} />
        <Number task={task} fieldID={'WorkRemaining'} />
        {isValidObject(assignedField) && (
          <Allocations task={task} fieldID={'ResourceAllocationFirst'} />
        )}
      </div>
    );
  } else if (
    !taskIsInSprint(task) &&
    task.fields.CommittedToProjectID !== undefined
  ) {
    prioritisedFields = (
      <div className="importantfields">
        {taskHasWorkflow(task) ? (
          <WorkflowStatusSingleSelect task={task} fieldID={'Status'} />
        ) : (
          <StatusSingleSelect task={task} />
        )}
        {isValidObject(assignedField) && (
          <Allocations task={task} fieldID={'ResourceAllocationFirst'} />
        )}
        <TimeZone task={task} fieldID={'TimeZoneStart'} />
        <TimeZone task={task} fieldID={'TimeZoneEnd'} />
      </div>
    );
  } else {
    prioritisedFields = (
      <div className="importantfields">
        {taskHasWorkflow(task) ? (
          <WorkflowStatusSingleSelect task={task} fieldID={'Status'} />
        ) : (
          <StatusSingleSelect task={task} />
        )}
        <PrioritySingleSelect task={task} fieldID={'BacklogPriority'} />
      </div>
    );
  }

  return (
    <div style={{ width: '100%', paddingRight: '10px' }}>
      {prioritisedFields}
      {fieldDefs.sort(sortByFieldName).map((field) => (
        <div key={field?.id}>{renderField(field?.id, singleLineFields)}</div>
      ))}
    </div>
  );
});

export default SingleLineTab;
