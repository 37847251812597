import React, { FC } from 'react';
import { Icon } from 'semantic-ui-react';
import { getThemeName } from '../../../util/localStorage';
import { Theme } from '../../../enums';
import { useDispatch } from 'react-redux';
import { toggleTheme } from '../../../store/appState/slice';

export const ThemeToggle: FC = () => {
  const dispatch = useDispatch();
  const onToggleTheme = () => {
    localStorage.setItem(
      'theme',
      getThemeName() === Theme.DARK ? Theme.LIGHT : Theme.DARK,
    );

    dispatch(toggleTheme());
  };

  return (
    <div className="profile-menu" onClick={onToggleTheme}>
      <Icon name="adjust" />
    </div>
  );
};

export default ThemeToggle;
