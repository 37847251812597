import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { history } from '../../util/history';
import { alertActions } from '../../actions/alert.constants';
import { reconnectHansoft } from '../../DDPJS/DDPJS';
import { doesRefreshTokenExist } from '../../tokens/refresh.token';
import { IntlProvider } from 'react-intl';
import enUS from '../../translations/en-US.json';
import enUK from '../../translations/en-UK.json';
import ja from '../../translations/ja.json';
import zhCN from '../../translations/zh-CN.json';
import RoutesComponent from '../Routes/RoutesComponent';
import japanese from 'date-fns/locale/ja';
import chinese from 'date-fns/locale/zh-CN';
import englishUS from 'date-fns/locale/en-US';
import englishUK from 'date-fns/locale/en-GB';
import { getIntegration } from '../../util/localStorage';
import 'semantic-ui-css/semantic.min.css';
import '../../palette.scss';
import { registerLocale } from 'react-datepicker';
import { Locale } from '../../enums';
import { Snackbar } from '../Snackbar';
import { GlobalState, SharedProps } from '../../interfaces';

export const App: FC = () => {
  registerLocale(Locale.JAPANESE, japanese);
  registerLocale(Locale.CHINESE, chinese);
  registerLocale(Locale.ENGLISH_UK, englishUK);
  registerLocale(Locale.ENGLISH_US, englishUS);

  if (doesRefreshTokenExist()) {
    console.log('Reconnecting to Hansoft');
    reconnectHansoft();
  }

  const dispatch = useDispatch();

  const theme = useSelector((state: GlobalState) => state.appState.theme);
  const locale = useSelector((state: GlobalState) => state.appState.locale);

  history.listen(() => {
    // clear alert on location change
    dispatch(alertActions.clear());
  });

  try {
    require('moment/locale/' + navigator.language);
    moment.locale(navigator.language);
  } catch (error) {
    try {
      if (navigator.language.indexOf('-') !== -1) {
        require('moment/locale/' + navigator.language.split('-')[0]);
        moment.locale(navigator.language.split('-')[0]);
      }
    } catch (innerError) {
      // TODO: Render error page if this error is thrown
    }
  }

  const messages = {
    'en-US': enUS,
    'en-UK': enUK,
    ja,
    'zh-CN': zhCN,
  };

  const onToggleIntegration = (integrationData: any): void => {
    let newData;
    if (integrationData !== undefined && integrationData !== null) {
      newData = {
        enabled: true,
        data: integrationData,
      };
    } else {
      newData = {
        enabled: false,
        data: {},
      };
    }
    sessionStorage.setItem('integration', JSON.stringify(newData));
    setIntegration(newData);
  };

  const handleRefresh = () => {
    console.log('Browser refresh');
    reconnectHansoft();

    return '';
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleRefresh);
  }, []);

  const [integration, setIntegration] = useState(getIntegration());

  const shared: SharedProps = {
    onToggleIntegration,
    integration,
  };

  return (
    <div className={`app theme--${theme}`}>
      <IntlProvider
        locale={locale}
        messages={messages[locale]}
        key={locale}
        onError={() => undefined}
      >
        <RoutesComponent shared={shared} />
      </IntlProvider>
      <Snackbar />
    </div>
  );
};

export default App;
