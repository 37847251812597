import { flowRight } from 'lodash';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { Theme } from '../../../../enums';
import {
  colorToRgbCodeInDarkMode,
  colorToRgbCodeInLightMode,
} from '../../../../mappers';
import { Image, Modal } from 'semantic-ui-react';
import { GlobalState, Task } from '../../../../interfaces';
import { ConnectDragSource, ConnectDropTarget } from 'react-dnd';
import { PrioritySingleSelect } from '../../../Fields/PrioritySingleSelect';
import {
  Comment,
  Link as LinkIcon,
  PaperClip,
  UserStory,
} from '../../../Icons';
import './Card.scss';
import { useIntl } from 'react-intl';
import { TooltipWrapper } from '../../../TooltipWrapper';
import { getThemeName } from '../../../../util/localStorage';
import { isValidObject } from '../../../../util/validationFunctions';
import {
  getTaskProperty,
  taskHasProperty,
  taskIsInSprint,
} from '../../../../util/task/propertyHelpers';
import * as VC from '../../../../util/versioncontrol';
import { fieldIsEnabled } from '../../../../util/task/fields/fieldIsEnabled';
import { dueDateGrouping } from '../../../../util/date-time/dueDateGrouping';
import { formatYYYYMMDD } from '../../../../util/date-time/dateTimeFormat';
import { currentToDoIsUserStory } from '../../../../util/task/currentToDoIsUserStory';
import SprintIconWithTooltip from '../../../Labels/SprintIconWithTooltip';
import { determineStartDateOfScheduledTask } from '../../../../util/task/determineStartDateOfScheduledTask';
import { determineSortDate } from '../../../../util/task/determineSortDate';

interface IProps {
  task: Task;
  connectDragSource: ConnectDragSource;
  connectDropTarget: ConnectDropTarget;
  columnId: number;
  isDragging: boolean;
  showCoverImages: boolean;
  title: string;
  disabled: boolean;
  hoveredUponCard: number;
  workflowID?: string | number;
}

export const Card: any = ({
  task,
  connectDragSource,
  connectDropTarget,
  title,
  isDragging,
  disabled,
  showCoverImages,
  hoveredUponCard,
  columnId,
  workflowID,
}: IProps) => {
  useEffect(() => {
    setBackgroundColor({
      backgroundColor: `rgb(${
        getThemeName() === Theme.LIGHT
          ? colorToRgbCodeInLightMode.get(task?.fields.Color)
          : colorToRgbCodeInDarkMode.get(task?.fields.Color)
      })`,
    });
  }, [task?.fields.Color, getThemeName()]);

  useEffect(() => {
    setShowAttachmentsIcon(
      isValidObject(task?.fields.AttachedDocuments.AttachedDocuments),
    );
  }, [task?.fields.AttachedDocuments]);

  useEffect(() => {
    setShowLinkedToIcon(
      isValidObject(task?.fields.LinkedTo) && task?.fields.LinkedTo?.length > 0,
    );
  }, [task?.fields.LinkedTo]);

  useEffect(() => {
    setShowHasCommentsIcon(taskHasProperty(task, 'Comment'));
  }, [task?.fields.Comment]);

  useEffect(() => {
    setShowAttachmentsIcon(
      isValidObject(task?.fields.AttachedDocuments.AttachedDocuments),
    );
  }, [task?.fields.AttachedDocuments]);

  const mainImageFileInfo = VC.mapMainImageToInfo(task);

  const [backgroundColor, setBackgroundColor] = useState({});
  const [showAttachmentsIcon, setShowAttachmentsIcon] = useState(false);
  const [showLinkedToIcon, setShowLinkedToIcon] = useState(false);
  const [showHasCommentsIcon, setShowHasCommentsIcon] = useState(false);
  const [keyImagePreviewOpen, setKeyImagePreviewOpen] = useState(false);

  const projects = useSelector((state: GlobalState) => state.projects);

  const databaseGUIDHash: string = useSelector(
    (state: GlobalState) => state.appState.databaseGUIDHash,
  );

  const intl = useIntl();

  const getSubProjectPath = useMemo(() => {
    if (task) {
      return `${
        projects.find((project: any) => project.id == task.$ProjectID)?.Name
      } ${
        task.fields.SubProjectPath ? ` / ${task.fields.SubProjectPath}` : ''
      }`;
    } else {
      return '';
    }
  }, [projects, task]);

  const getCardStatusStylingClass = () => {
    if (isDragging && hoveredUponCard === undefined) {
      return 'Card--dragging';
    } else if (task) {
      return 'Card--normal';
    } else {
      return '';
    }
  };

  const hasBugPriority:
    | (number | boolean)[]
    | (string | boolean)[]
    | undefined = task ? fieldIsEnabled('BugPriority', task) : undefined;

  const getScheduledTaskEndDate = task ? determineSortDate(task) : undefined;
  const getScheduledTaskStartDate = determineStartDateOfScheduledTask(task);

  const checkIfTaskIsPastDue: boolean = useMemo(() => {
    if (task) {
      return dueDateGrouping(task) === 1 && task.fields.Status !== 4;
    } else {
      return false;
    }
  }, [task]);

  return flowRight(
    connectDragSource,
    connectDropTarget,
  )(
    <div
      id={`${
        task
          ? `task-${task?.$ID}`
          : `empty-column-${columnId}-workflow-${workflowID}-spacer`
      }`}
      className={`card ${getCardStatusStylingClass()} 
       ${disabled && hoveredUponCard !== columnId ? 'Card--disabled' : ''}  ${
        checkIfTaskIsPastDue ? 'past-due' : ''
      }`}
    >
      {isValidObject(task?.fields.Color) && task?.fields.Color !== 1 && (
        <div className="color" style={backgroundColor} />
      )}
      {isValidObject(task) && (
        <div className="Card--body">
          {getTaskProperty(task, 'LinkedToSprint') === null &&
          getScheduledTaskEndDate &&
          getScheduledTaskStartDate ? (
            <div className="scheduled-item-due-date">{`${formatYYYYMMDD(
              new Date(getScheduledTaskStartDate),
            )} - ${formatYYYYMMDD(new Date(getScheduledTaskEndDate))} - ${
              task?.fields.Duration
            }${intl.formatMessage({
              id: 'GENERAL.daysAbbreviation',
              defaultMessage: 'd',
            })}`}</div>
          ) : (
            <PrioritySingleSelect
              task={task}
              fieldID={
                hasBugPriority !== undefined && hasBugPriority[0]
                  ? 'BugPriority'
                  : 'SprintPriority'
              }
              isLabel
            />
          )}

          <div className="description-with-image">
            {showCoverImages && mainImageFileInfo && (
              <Image
                className="cover-image"
                inline
                src={mainImageFileInfo.url}
                onClick={() => setKeyImagePreviewOpen(true)}
              />
            )}
            <div className="description">
              <div className="project">{getSubProjectPath}</div>
              <Link to={`task/${databaseGUIDHash}/${task?.$ID}`}>
                <span title={title}>
                  {title !== ''
                    ? title
                    : `(${intl.formatMessage({ id: 'GENERAL.noName' })})`}
                </span>
              </Link>
            </div>
          </div>
          <div className="information-icons">
            {taskIsInSprint(task) && (
              <div className="icon">
                <SprintIconWithTooltip task={task} />
              </div>
            )}
            {showHasCommentsIcon && (
              <div className="icon">
                <TooltipWrapper
                  trigger={
                    <Comment
                      color={getThemeName() === Theme.LIGHT ? 'black' : 'white'}
                    />
                  }
                  content={intl.formatMessage({ id: 'BOARDS.hasComments' })}
                />
              </div>
            )}
            {showLinkedToIcon && (
              <div className="icon">
                <TooltipWrapper
                  trigger={
                    <LinkIcon
                      color={getThemeName() === Theme.LIGHT ? 'black' : 'white'}
                    />
                  }
                  content={`${intl.formatMessage({
                    id: 'BOARDS.linkedTo',
                  })} ${task.fields.LinkedTo.length} ${intl.formatMessage({
                    id: 'BOARDS.item(s)',
                  })}`}
                />
              </div>
            )}
            {showAttachmentsIcon && (
              <div className="icon">
                <TooltipWrapper
                  trigger={
                    <PaperClip
                      color={getThemeName() === Theme.LIGHT ? 'black' : 'white'}
                    />
                  }
                  content={intl.formatMessage({ id: 'BOARDS.hasAttachments' })}
                />
              </div>
            )}
            {currentToDoIsUserStory(task.$ID) && (
              <div className="icon">
                <TooltipWrapper
                  trigger={<UserStory />}
                  content={intl.formatMessage({ id: 'ITEM_DETAILS.userStory' })}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {mainImageFileInfo && (
        <Modal
          className="lightbox"
          closeIcon
          open={keyImagePreviewOpen}
          onClose={() => setKeyImagePreviewOpen(false)}
        >
          <Modal.Content image>
            <Image
              centered
              src={mainImageFileInfo.url}
              onClick={() => setKeyImagePreviewOpen(false)}
            />
          </Modal.Content>
        </Modal>
      )}
    </div>,
  );
};

export default Card;
