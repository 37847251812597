import { FC, memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { SingleLineTab } from './SingleLineTab';
import './ItemDetails.scss';
import { CommentsTab } from './CommentsTab';
import { TaskCarousel } from '../TaskCarousel';
import { ItemDescription } from '../ItemDescription';
import { isValidObject } from '../../../util/validationFunctions';
import { ImgPipeline, WatchIcon } from '../../../images/images';
import {
  getLoggedInResourceID,
  subscribeWithParams,
  unsubscribeFromSubscriptionId,
  setField,
} from '../../../DDPJS/DDPJS';
import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex';

import 'react-reflex/styles.css';
import { Attachments } from '../../Fields/Attachements';
import { Image, Loader, Modal, Tab } from 'semantic-ui-react';
import { setErrorMessageToStore } from '../../MessageDialog/MessageUtilities';
import { MsgLevelEnum, MessageEnum } from '../../MessageDialog/MessageIndex';
import { Relation } from '../Relations/Relation';
import ReleaseTag from '../ReleaseTag/ReleaseTag';
import { LeftPanel } from '../LeftPanel';
import { camelCase } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { getProjectInfo } from '../../../util/project/getProjectInfo';
import { isTaskVisibleInTodo } from '../../../util/task/isTaskVisibleInTodo';
import {
  getTaskProperty,
  taskIsInSprint,
  toggleWatchingOfTask,
  userIsWatchingTask,
} from '../../../util/task/propertyHelpers';
import {
  clearCurrentTaskID,
  setCurrentTaskID,
} from '../../../store/appState/slice';
import { getUniqueFieldForType } from '../../../util/task/itemDetailFunctions';
import { RichTextEditor } from '../../Fields/RichTextEditor';
import { HansoftLink } from '../HansoftLInk';
import { Sprint } from '../Sprint';
import { GlobalState, MultiLineField, Task } from '../../../interfaces';
import * as VC from '../../../util/versioncontrol';

interface IProps {
  id: string;
  databaseGUIDHash: string;
}

export const ItemDetails: FC<IProps> = memo(({ id, databaseGUIDHash }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const subscribeToTask = (taskId: string) => {
    return subscribeWithParams('Tasks', [taskId], {
      onReady: () => {
        dispatch(
          setCurrentTaskID({
            currentTaskID: taskId,
          }),
        );

        setInitialLoadDone(true);
      },
      onNoSub: () => {
        setErrorMessageToStore(
          MsgLevelEnum.INFORMATION,
          MessageEnum.TASK_NOT_FOUND,
        );

        setTaskId('');
        setInitialLoadDone(true);
        dispatch(clearCurrentTaskID());
      },
    });
  };

  const getMultilinePanels = (): any[] => {
    const panels = [];
    const currentId = id;

    const task = todos.find((item) => item.$ID === currentId);

    if (task) {
      const projectData = getProjectInfo(task.$ProjectID);
      let myProjectID = projectData[1] !== null ? projectData[1].id : 0;
      if (
        (projectData[0]?.Type === 'QA' || projectData[0]?.Type === 'Backlog') &&
        !taskIsInSprint(task)
      ) {
        myProjectID = parseInt(task.$ProjectID, 10);
      }

      let myMultilineFields = multiLineFields
        .filter((field) => field.projectID === myProjectID)
        .sort(sortByFieldName);

      if (projectData[0]?.Type === 'QA' && taskIsInSprint(task)) {
        const QAProjectID = parseInt(task.$ProjectID, 10);
        myMultilineFields = myMultilineFields.concat(
          multiLineFields.filter((field) => {
            if (field.projectID !== QAProjectID) return false;
            return (
              myMultilineFields.filter(
                (existingField) => existingField.id === field.id,
              ).length < 1
            );
          }),
        );
        myMultilineFields.sort(sortByFieldName);
      }

      const fieldLength = myMultilineFields.length;

      const projectType = projectData[0]?.Type;
      const uniqueField = getUniqueFieldForType(projectType);

      for (let iter = 0; iter < fieldLength; iter++) {
        if (
          uniqueField !== null &&
          myMultilineFields[iter].id === uniqueField[0]
        ) {
          continue;
        }

        const fieldName = myMultilineFields[iter].DisplayName;
        let fieldValue = '';
        if (myMultilineFields[iter].id in task.fields) {
          fieldValue = task.fields[myMultilineFields[iter].id];
        }

        const fieldData = {
          id: task.$ID,
          fieldID: myMultilineFields[iter].id,
          readOnly: myMultilineFields[iter].ReadOnly,
          fieldValue: fieldValue,
        };

        if (showUserStoryField(task, myMultilineFields[iter].id)) {
          panels.push({
            menuItem: intl.formatMessage({
              id: `ITEM_DETAILS.${camelCase(fieldName)}`,
              defaultMessage: fieldName,
            }),
            render: () => (
              <Tab.Pane style={BackgroundStyle} attached={false}>
                <RichTextEditor {...fieldData} />
              </Tab.Pane>
            ),
          });
        }
      }

      return panels;
    } else {
      return [];
    }
  };

  const [initialLoadDone, setInitialLoadDone] = useState(false);
  const [taskId, setTaskId] = useState(id);
  const [keyImagePreviewOpen, setKeyImagePreviewOpen] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [multilineTabsAreVisible, setMultilineTabsAreVisible] = useState(false);
  const [multilinePanels, setMultilinePanels] = useState<any[]>([]);
  const [layout, setLayout] = useState({
    singleline: {
      flex: 0.5,
    },
    multiline: {
      flex: 0,
    },
    comments: {
      flex: 0.5,
    },
  });
  const [projectIsQA, setProjectIsQA] = useState(false);

  const todos = useSelector((state: GlobalState) => state.todos);
  const sprints = useSelector((state: GlobalState) => state.sprints);
  const appState = useSelector((state: GlobalState) => state.appState);
  const milestones = useSelector((state: GlobalState) => state.milestones);
  const multiLineFields = useSelector(
    (state: GlobalState) => state.multiLineFields,
  );

  useEffect(() => {
    if (taskId !== id) {
      setTaskId(id);
      setInitialLoadDone(false);
      setSubscriptionId(subscribeToTask(id));
    }
  }, [id]);

  useEffect(() => {
    return () => {
      if (subscriptionId !== '') {
        unsubscribeFromSubscriptionId(subscriptionId);
      }
      dispatch(clearCurrentTaskID());
    };
  }, [subscriptionId]);

  useEffect(() => {
    setMultilinePanels(getMultilinePanels());
  }, [initialLoadDone, todos]);

  useEffect(() => {
    updateMultilineTabsVisible(multilinePanels.length > 0);
    updateProjectIsQAFlag();
  }, [multilinePanels]);

  useEffect(() => {
    setLayout(getLayoutState());
  }, [multilineTabsAreVisible]);

  useMemo(() => {
    const subscriptionID = subscribeToTask(id);
    setSubscriptionId(subscriptionID);
  }, []);

  useEffect(() => {
    return () => {
      if (subscriptionId !== '') {
        unsubscribeFromSubscriptionId(subscriptionId);
      }
      dispatch(clearCurrentTaskID());
    };
  }, []);

  const sortByFieldName = (
    first: MultiLineField,
    second: MultiLineField,
  ): number => {
    if (first.DisplayName.toUpperCase() < second.DisplayName.toUpperCase()) {
      return -1;
    } else if (
      first.DisplayName.toUpperCase() > second.DisplayName.toUpperCase()
    ) {
      return 1;
    } else {
      return 0;
    }
  };

  const showUserStoryField = (task: Task, fieldID: string) => {
    if (fieldID === 'DetailedDescription') {
      return getTaskProperty(task, 'UserStoryFlag');
    }
    return true;
  };

  const onResizePane = (event: any) => {
    const { flex } = event.component.props;
    const { key } = event.component;
    const newLayout = { ...layout };

    newLayout[key.replace('.$', '')].flex = flex;

    if (multilineTabsAreVisible || projectIsQA) {
      localStorage.setItem('hs-reflex-itemdetails', JSON.stringify(newLayout));
    } else {
      localStorage.setItem(
        'hs-reflex-item-details-without-multiline',
        JSON.stringify(newLayout),
      );
    }
    setLayout(newLayout);
  };

  const getLayoutState = () => {
    if (multilineTabsAreVisible || projectIsQA) {
      const item = localStorage.getItem('hs-reflex-itemdetails');
      if (item) return JSON.parse(item);
      return {
        singleline: {
          flex: 0.4,
        },
        multiline: {
          flex: 0.3,
        },
        comments: {
          flex: 0.3,
        },
      };
    } else {
      const item = localStorage.getItem(
        'hs-reflex-item-details-without-multiline',
      );
      if (item) return JSON.parse(item);
      return {
        singleline: {
          flex: 0.5,
        },
        multiline: {
          flex: 0,
        },
        comments: {
          flex: 0.5,
        },
      };
    }
  };

  const updateProjectIsQAFlag = () => {
    const currentId = id;

    const task = todos.find((item) => item.$ID === currentId);

    if (task) {
      const projectData = getProjectInfo(task.$ProjectID);
      setProjectIsQA(projectData[0]?.Type === 'QA');
    }
  };

  const updateMultilineTabsVisible = (isVisible: boolean) => {
    setMultilineTabsAreVisible(isVisible);
  };

  if (appState.databaseGUIDHash !== databaseGUIDHash || taskId === '') {
    return <Redirect to={'/'} />;
  } else if (!initialLoadDone) {
    return (
      <div className="ItemDetail-Page">
        <Loader active />
      </div>
    );
  }
  const currentId = id;

  const currentItem = todos.find((item) => item.$ID === currentId);
  const mainImageFileInfo = VC.mapMainImageToInfo(currentItem);

  return (
    <div className="ItemDetail-Page">
      <div className="itemdetail-topbar">
        <div className="milestones">
          {isValidObject(currentItem) ? (
            <ReleaseTag task={currentItem} milestones={milestones} />
          ) : (
            <div />
          )}
          {currentItem && (
            <Attachments
              key={'AttachedDocuments'}
              task={currentItem}
              attachedDocuments={
                currentItem?.fields.AttachedDocuments
                  ? currentItem.fields.AttachedDocuments.AttachedDocuments
                  : []
              }
            />
          )}
        </div>
        <div className="right-top">
          {currentItem && (
            <div
              className="watch"
              onClick={() => {
                setField(
                  'CommentsOptions',
                  currentItem.$ID,
                  toggleWatchingOfTask(currentItem),
                );
              }}
            >
              <WatchIcon />
              <div>
                {userIsWatchingTask(currentItem) ? (
                  <FormattedMessage id="ITEM_DETAILS.unwatch" />
                ) : (
                  <FormattedMessage id="ITEM_DETAILS.watch" />
                )}
              </div>
            </div>
          )}
          {currentItem && (
            <HansoftLink task={currentItem} theme={appState.theme} />
          )}
          <div>
            {currentItem &&
              isTaskVisibleInTodo(currentItem, getLoggedInResourceID()) && (
                <TaskCarousel task={currentItem} />
              )}
          </div>
        </div>
      </div>

      <div className="description">
        {mainImageFileInfo && (
          <Image
            className="cover-image"
            inline
            src={mainImageFileInfo.url}
            onClick={() => setKeyImagePreviewOpen(true)}
          />
        )}
        {currentItem && (
          <ItemDescription task={currentItem} fieldID={'Description'} />
        )}
      </div>
      <div className="sprint">
        {currentItem && <Sprint task={currentItem} sprints={sprints} />}
        {currentItem && currentItem.fields.LinkedToPipeline && (
          <Relation
            icon={ImgPipeline}
            text={currentItem.fields.LinkedToPipelineTaskName}
          />
        )}
      </div>
      <div className="itemdetails-panes">
        <ReflexContainer orientation="vertical">
          <ReflexElement
            key="singleline"
            style={{ overflowX: 'hidden' }}
            onResize={onResizePane}
            flex={layout?.singleline.flex}
          >
            <div className="pane-content">
              {currentItem && (
                <SingleLineTab
                  task={currentItem}
                  milestones={milestones}
                  theme={appState.theme}
                />
              )}
            </div>
          </ReflexElement>
          {(projectIsQA || multilineTabsAreVisible) && (
            <ReflexSplitter className="pane-splitter">
              <div className="splitter-content" />
            </ReflexSplitter>
          )}

          {(projectIsQA || multilineTabsAreVisible) && (
            <ReflexElement
              key="multiline"
              style={{ overflowX: 'hidden' }}
              onResize={onResizePane}
              flex={layout?.multiline.flex}
            >
              <div className="pane-content">
                {currentItem && (
                  <LeftPanel task={currentItem} panels={multilinePanels} />
                )}
              </div>
            </ReflexElement>
          )}

          <ReflexSplitter className="pane-splitter">
            <div className="splitter-content" />
          </ReflexSplitter>

          <ReflexElement
            key="comments"
            style={{ overflowX: 'hidden' }}
            onResize={onResizePane}
            flex={layout?.comments.flex}
          >
            <div className="pane-content">
              {currentItem && <CommentsTab task={currentItem} />}
            </div>
          </ReflexElement>
        </ReflexContainer>
      </div>
      {mainImageFileInfo && (
        <Modal
          className="lightbox"
          closeIcon
          open={keyImagePreviewOpen}
          onClose={() => setKeyImagePreviewOpen(false)}
        >
          <Modal.Content image>
            <Image
              centered
              src={mainImageFileInfo.url}
              onClick={() => setKeyImagePreviewOpen(false)}
            />
          </Modal.Content>
        </Modal>
      )}
    </div>
  );
});

const BackgroundStyle = {
  margin: '0',
  padding: '0',
  backgroundColor: 'transparent',
};

export default ItemDetails;
