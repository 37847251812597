import { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Dropdown, Icon } from 'semantic-ui-react';
import { checkFlagValue } from '../../../../../util/localStorage';
import './ShowOptions.scss';
import { subscribe, unsubscribe } from '../../../../../DDPJS/DDPJS';
import { useDispatch, useSelector } from 'react-redux';
import { toggleShowCoverImages } from '../../../../../store/appState/slice';
import { GlobalState } from '../../../../../interfaces';

export const ShowOptions: FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const showCoverImages = useSelector(
    (state: GlobalState) => state.appState.showCoverImages,
  );

  const onToggleCoverImages = () => {
    localStorage.setItem(
      'showCoverImages',
      String(!checkFlagValue('showCoverImages')),
    );

    dispatch(toggleShowCoverImages());
  };

  const onToggleShowCompletedItems = useCallback(() => {
    unsubscribe();
    localStorage.setItem(
      'showCompletedItems',
      String(!checkFlagValue('showCompletedItems')),
    );

    subscribe();
  }, [checkFlagValue('showCompletedItems')]);

  const onToggleShowOnlyFourWeeksOfTasks = useCallback(() => {
    unsubscribe();

    localStorage.setItem(
      'showOnlyFourWeeksOfTasks',
      String(!checkFlagValue('showOnlyFourWeeksOfTasks')),
    );

    subscribe();
  }, [checkFlagValue('showOnlyFourWeeksOfTasks')]);

  const onToggleShowPipelineTasksThatCannotStart = useCallback(() => {
    unsubscribe();
    localStorage.setItem(
      'showPipelineTasksThatCannotStart',
      String(!checkFlagValue('showPipelineTasksThatCannotStart')),
    );

    subscribe();
  }, [checkFlagValue('showPipelineTasksThatCannotStart')]);

  return (
    <Dropdown
      id="show-options-button"
      className="show-options icon"
      icon="eye"
      text={intl.formatMessage({ id: 'GENERAL.show' })}
      direction="left"
    >
      <Dropdown.Menu>
        <Dropdown.Item
          className="item-with-checkbox"
          onClick={onToggleCoverImages}
          text={intl.formatMessage({ id: 'GENERAL.coverImages' })}
          icon={<Icon name="checkmark" inverted={!showCoverImages} />}
        />
        <Dropdown.Item
          className="js-menu-coverimages item-with-checkbox"
          onClick={onToggleShowCompletedItems}
          text={intl.formatMessage({ id: 'GENERAL.completedItems' })}
          icon={
            <Icon
              name="checkmark"
              inverted={!checkFlagValue('showCompletedItems')}
            />
          }
        />
        <Dropdown.Item
          className="js-menu-tasks-cannot-start item-with-checkbox"
          onClick={onToggleShowPipelineTasksThatCannotStart}
          text={intl.formatMessage({
            id: 'GENERAL.pipelineTasksThatCannotStart',
          })}
          icon={
            <Icon
              name="checkmark"
              inverted={!checkFlagValue('showPipelineTasksThatCannotStart')}
            />
          }
        />
        <Dropdown.Item
          className="item-with-checkbox"
          text={intl.formatMessage({
            id: 'GENERAL.onlyFourWeeksOfTasks',
          })}
          onClick={onToggleShowOnlyFourWeeksOfTasks}
          icon={
            <Icon
              name="checkmark"
              inverted={!checkFlagValue('showOnlyFourWeeksOfTasks')}
            />
          }
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ShowOptions;
